<template>
  <div class="bigbox">
    <div>
      <nav2 />
    </div>
    <van-tabs @click="btn2"
              v-model="active">
      <van-tab title="积分券">
        <van-divider />
        <div class="carbox"
             v-for="(v,i) in FileList"
             :key="i">
          <div class="imgbox">
            <img src="../assets/images/car.png"
                 alt />
          </div>
          <div style="line-height:23px;box-sizing: border-box;flex:1;margin-left:10px">
            <div style="font-weight:bold;">{{v.title}}</div>
            <div style="color:red;font-size:16px;font-weight:bold;">￥{{v.integral}}</div>

            <div style="color:#888;width:150px">{{v.expirationTime}} </div>
          </div>
          <div @click="btnbox"
               style="border-left: dashed 1px #ccc;margin-right:5px;">
            <button class="btn">去使用</button>
          </div>

        </div>
      </van-tab>
      <van-tab title="折扣券">
        <van-divider />
        <div class="carbox2">
          <div style="padding-top:10px"
               class="topcar"
               v-for="(v,i) in FileList"
               :key="i">
            <img src="../assets/images/wt.png"
                 alt />
            <div>
              <div style="font-size:16px">{{v.title}}</div>
              <div>
                <span style="font-size:16px;color:#FF4500">{{v.discount}}</span> 折
              </div>
            </div>
            <div class="btnbox"
                 @click="go">
              <button class="btn">去使用</button>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="满减券">
        <van-divider />
        <div class="carbox"
             v-for="(v,i) in FileList"
             :key="i">
          <div class="imgbox">
            <img src="../assets/images/car.png"
                 alt />
          </div>
          <div style="line-height:23px;margin-left:20px;width:70%;box-sizing: border-box;">
            <div style="font-weight:bold;">{{v.title}}</div>
            <div style="color:red;font-size:16px;font-weight:bold;">
              ￥{{v.reduceMoney}}
              <span style="color:#aaa;font-size:14px">（满{{v.fullMoney}} 使用）</span>
            </div>
            <div style="color:#888;width:150px">{{v.expirationTime}} 到期</div>
          </div>
          <div style="border-left: dashed 1px 	#ccc;margin-right:10px"
               @click="go(v.type)">
            <button class="btn">去使用</button>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { discount_api, useco_api } from '../http/api/service'
import nav2 from '@/components/Nav2.vue'
import { Toast } from 'vant';
export default {
  name: 'TaxiH5OrderDetails',
  components: {
    nav2,
  },

  data () {
    return {
      active: 2,
      FileList: [],
      id: ''
    }
  },

  mounted () {
    this.card()

  },

  methods: {
    //使用跳转
    go (e) {
      console.log(e);
      if (e == 3) {
        this.$router.push({
          path: '/home'
        })
      } else {
        this.$router.push({
          path: '/home2'
        })
      }

    },
    //使用
    btnbox () {
      this.coupon()


    },
    //满减卷
    btn2 (name, title) {
      console.log(name, title)
      this.FileList = []
      this.card()
    },
    //优惠券
    async card () {
      //const token =JSON.parse( sessionStorage.getItem('token')).token;
      console.log(11)
      let res = await discount_api({
        type: this.active + 1,
      })
      console.log(res)
      if (res.code == 200) {
        this.FileList = res.data
      }
    },
    async coupon () {
      let tk = JSON.parse(sessionStorage.getItem('token')).token
      this.FileList.forEach((v) => {
        this.id = v.id
      })

      let res = await useco_api({
        token: tk,
        id: this.id, //优惠券主键id
      })
      console.log(res)
      if (res.code == 200) {
        Toast(res.message);
        this.FileList = []
        this.card()
        this.$router.push({
          path: '/index'
        })
      }
    }

  },
}
</script>

<style lang="scss" scoped>
.van-divider {
  margin: 0;
  border-top: 1px solid #ccc;
}
.bigbox {
  width: 100%;
  height: 100vh;
  .carbox {
    width: 100%;
    display: flex;
    height: 100px;
    border-bottom: solid 1px #ccc;
    margin: 10px 0;

    box-sizing: border-box;
    .imgbox {
      width: 30%;
      img {
        width: 90pt;
        height: 60pt;
      }
    }

    .btn {
      background: #fff;
      border: #f60;
      background: #f60;
      color: #eb4541;
      width: 60px;
      height: 30px;
      margin-left: 5px;
      margin-top: 50%;
      border-radius: 10%;
      color: #fff;
    }
  }
  .carbox2 {
    // height: 300px;
    .topcar {
      display: flex;
      justify-content: space-between;
      margin-left: 5%;
      width: 90%;
      height: 100px;
      background: #fff;
      border-radius: 16pt;
      line-height: 30px;
      box-sizing: border-box;
      align-items: center;
      margin-top: 20px;

      box-shadow: 0 0 10px rgba(173, 171, 171, 0.3);

      img {
        width: 90pt;
        height: 60pt;
      }
      .btnbox {
        .btn {
          background: #fff;
          border: #f60;
          background: #f60;
          color: #eb4541;
          width: 60px;
          height: 30px;
          margin-left: 5px;

          border-radius: 10%;
          color: #fff;

          margin-right: 20px;
        }
      }
    }
  }
  .goodbox {
    width: 90%;
    height: 300px;
    background: #eee;
    margin: 0 5%;
    border-radius: 15px;
    margin-top: 10px;
    .back {
      width: 90%;
      height: 100px;
      margin: 10px 5%;
      background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng52559c42f93886e3afd9f54b916790890942a16a13c6d4e67617f9efe1396a03)
        no-repeat;
      display: flex;
      justify-content: space-between;
    }
  }
  .footbtnbox {
    width: 80%;
    height: 50px;
    background: #eb4541;
    color: #fff;
    border-radius: 15px;
    border: 0;
    margin-left: 10%;
  }
}
</style>